import React from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import Img from "gatsby-image"

import Layout from "../components/layout"
import SEO from "../components/seo"

const AnimFr = ({ location }) => {
  const data = useStaticQuery(graphql`
    query {
      music: file(relativePath: { eq: "mascotteMusique.png" }) {
        childImageSharp {
          fixed(width: 260) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      disque: file(relativePath: { eq: "disque.png" }) {
        childImageSharp {
          fixed(width: 87) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      cle: file(relativePath: { eq: "cle.png" }) {
        childImageSharp {
          fixed(width: 113) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      cassette: file(relativePath: { eq: "cassette.png" }) {
        childImageSharp {
          fixed(width: 100) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      danse: file(relativePath: { eq: "danse.png" }) {
        childImageSharp {
          fixed(width: 146) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      boule: file(relativePath: { eq: "boule.png" }) {
        childImageSharp {
          fixed(width: 146) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      quizz: file(relativePath: { eq: "quizz.png" }) {
        childImageSharp {
          fixed(width: 234) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      chanter: file(relativePath: { eq: "chanter.png" }) {
        childImageSharp {
          fixed(width: 140) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      ballons: file(relativePath: { eq: "ballons.png" }) {
        childImageSharp {
          fixed(width: 123) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      mariage: file(relativePath: { eq: "mariage.png" }) {
        childImageSharp {
          fixed(width: 117) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      thes: file(relativePath: { eq: "thes.jpg" }) {
        childImageSharp {
          fixed(width: 200) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      loto: file(relativePath: { eq: "loto.png" }) {
        childImageSharp {
          fixed(width: 504) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      micro: file(relativePath: { eq: "micro.jpg" }) {
        childImageSharp {
          fixed(width: 146) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      enceinte: file(relativePath: { eq: "enceinte.png" }) {
        childImageSharp {
          fixed(width: 68) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `)
  return (
    <Layout location={location}>
      <SEO
        title="Animation musicale"
        description="Soirée, karaoké, anniversaire, mariage, thé dansant, loto, sonorisation d'évènement"
      />
      <div className="submenu">
        <a href="#les_soiree">Soirées</a>
        <a href="#karaoke">Karaoké</a>
        <a href="#anniversaires">Anniversaires</a>
        <a href="#mariages">Mariages</a>
        <a href="#thes">Thés dansants</a>
        <a href="#loto">Loto</a>
        <a href="#sport">Manifestations sportives</a>
        <a href="#event">Sonorisation de vos événements </a>
      </div>
      <div className="columns is-vcentered">
        <div className="column is-3 has-text-centered">
          <Img
            fixed={data.music.childImageSharp.fixed}
            alt="Animation musicale"
            style={{ width: 260 }}
          />
        </div>
        <div className="column">
          <p>
            Ma passion pour la musique est très ancienne. Je baigne dans le
            milieu musical depuis mon enfance.
          </p>
          <p>
            Mon père a depuis très longtemps le goût du chant choral, d’abord
            classique puis jazz. Déjà enfant impossible de manquer un de ses
            concerts, il n’était pas rare que je sois au premier rang.
          </p>
          <p>
            Les repas du dimanche, chez mes grands parents, étaient également
            souvent ponctués de chants du terroir comtois.
          </p>
          <div className="columns is-vcentered">
            <div className="column is-2 has-text-centered">
              <Img
                fixed={data.disque.childImageSharp.fixed}
                alt="Disque"
                style={{ maxWidth: 87, width: "100%" }}
              />
            </div>
            <div className="column">
              <p>
                Adolescent, je découvris avec émotion les joies de la radio,
                dont « radio lacuzon ». Souvenirs nostalgiques des K7 que l’on
                rembobinait parfois avec nos crayons bic, et que nous écoutions
                avec nos baladeurs. De multiples univers s'ouvraient à moi.
                Premier coup de cœur pour Abba, puis très vite le Rock'n Roll
                avec Elvis, et le 45 tours des Chaussettes Noires.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="columns is-vcentered">
        <div className="column is-2 has-text-centered">
          <Img
            fixed={data.cle.childImageSharp.fixed}
            alt="Clé USB"
            style={{ maxWidth: 113, width: "100%" }}
          />
        </div>
        <div className="column">
          <p>
            J’ai toujours beaucoup écouté la radio quelque soit les lieux et les
            circonstances.
          </p>
          <p>
            Adulte, je me suis inscrit dans une chorale. Je pratique
            actuellement à l’une d’elle proche de mon domicile.
          </p>
          <p>
            Je vais très régulièrement à des concerts et j’aime découvrir de
            nouveaux genres et de nouveaux styles.
          </p>
          <p>
            Ma discothèque est immense et variée, elle s’adaptera à vos goûts et
            à vos demandes.{" "}
          </p>
        </div>
        <div className="column is-2 has-text-centered">
          <Img
            fixed={data.cassette.childImageSharp.fixed}
            alt="Cassette"
            style={{ maxWidth: 100, width: "100%" }}
          />
        </div>
      </div>
      <h2 id="les_soiree">Les soirées</h2>
      <h3>Soirées dansantes</h3>
      <div className="columns is-vcentered">
        <div className="column">
          <p>
            Grâce à un matériel professionnel, Dj "Ludo" saura vous faire
            chavirer jusqu’au bout de la nuit que ce soit
          </p>
          <p>avec les démons de minuit ou avec des partenaires particuliers.</p>
          <p>
            Sur la piste de danse, on danse le Mia ou la salsa du démon, comme
            au bon vieux temps du Rock'n Roll.
          </p>
          <p>
            Tout style : classique, avec les valses, tango, marches, paso,
            rockn’roll, disco, rock, années 80, dance,
          </p>
          <p>
            techno, chansons festives et collectives, latino, soul, ainsi que
            quelques moments de tendresse
          </p>
          <p>
            avec des slows langoureux ou rock.Un Dj s'adapte à son public
            quoiqu'il arrive.
          </p>
          <p>
            Sur tous les tempos, vous serez emmené par les démons de la danse à
            faire n’importe quoi !
          </p>
          <p>
            Mon objectif est votre satisfaction et faire de cette soirée un
            moment agréable et festif.
          </p>
        </div>
        <div className="column is-3 has-text-centered">
          <Img
            fixed={data.danse.childImageSharp.fixed}
            alt="Danse"
            style={{ maxWidth: 146, width: "100%" }}
          />
        </div>
      </div>
      <h3>Soirées années 80' </h3>
      <div className="has-text-centered mb-2 mt-2">
        <Img
          fixed={data.boule.childImageSharp.fixed}
          alt="Boule"
          style={{ maxWidth: 146, width: "100%" }}
        />
      </div>
      <p>
        Période prolifique en matière de production musicale, tout le monde aime
        ces tubes remplis de souvenirs heureux ou nostalgiques . Nul doute que
        vous vous laisserez entrainer par les démons de minuit ou de la danse.
        Quelques incartades seront quand même prévu dans les années précédentes
        et celles de nos jours. Un ouragan souffle sur la piste de danse, mais
        comme la musique est bonne pas besoin de boule de flipper car tout roule
        car c'est la ouate qu'elle préfère!
      </p>
      <h3 className="mt-6">Soirées Jeux et quizz</h3>
      <div className="has-text-centered mb-2 mt-2">
        <Img
          fixed={data.quizz.childImageSharp.fixed}
          alt="quizz"
          style={{ maxWidth: 234, width: "100%" }}
        />
      </div>
      <p>
        Si vous êtes incollables sur vos séries préférées, les dessins animés,
        et vos films fétiches, cette soirée est pour vous. Sans oublier quelques
        questions sur la culture et la gastronomie locale. Il y en aura pour
        tous les goûts et pour tous les âges, par équipe le combat fait rage ,
        mais toujours dans la bonne humeur et la courtoisie. Animateur/Dj pour
        vos jeux et quizz!
      </p>
      <h2 className="mt-6" id="karaoke">Le Karaoké</h2>
      <div className="columns is-vcentered">
        <div className="column is-2 has-text-centered">
          <Img
            fixed={data.chanter.childImageSharp.fixed}
            alt="chanter"
            style={{ maxWidth: 140, width: "100%" }}
          />
        </div>
        <div className="column">
          <p>
            Que vous sachiez chanter ou non, pas besoin de prendre du plaisir à
            le faire ?
          </p>
          <p>
            Cette discipline venue du Japon, apparait surtout en France dans les
            années 90.
          </p>
          <p>
            C’est surtout l’occasion d’un moment entre amis, collègues, famille,
            sans se prendre au sérieux.
          </p>
          <p>
            La convivialité et l’humour sont la base du Karaoké. Tout le monde
            chante sur les tubes et les grands standards . Dj "ludo" poussera la
            chansonnette s'il le faut!
          </p>
          <p>Pour les bars, restaurants, groupes d'amis...</p>
          <p>On rit beaucoup !</p>
        </div>
      </div>
      <div className="columns is-multiline" style={{justifyContent: "center"}}>
        <div className="column is-3">
          <iframe className="mt-3" style={{width:"100%"}} src="https://www.youtube-nocookie.com/embed/avrYKotSw4w" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
        </div>
        <div className="column is-3">
          <iframe className="mt-3" style={{width:"100%"}} src="https://www.youtube-nocookie.com/embed/ubOo0p-wS3k" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
        </div>
        <div className="column is-3">
          <iframe className="mt-3" style={{width:"100%"}} src="https://www.youtube-nocookie.com/embed/OFtH6YXU0Q0" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
        </div>
        <div className="column is-3">
          <iframe className="mt-3" style={{width:"100%"}} src="https://www.youtube-nocookie.com/embed/rk_JYt6N0a8" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
        </div>
        <div className="column is-3">
          <iframe className="mt-3" style={{width:"100%"}} src="https://www.youtube-nocookie.com/embed/6W7hOj4VjXM" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
        </div>
        <div className="column is-3">
          <iframe className="mt-3" style={{width:"100%"}} src="https://www.youtube-nocookie.com/embed/N_-j1s_Zx-c" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
        </div>
        <div className="column is-3">
          <iframe className="mt-3" style={{width:"100%"}} src="https://www.youtube-nocookie.com/embed/YVFLvKRloGc" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
        </div>
        <div className="column is-3">
          <iframe className="mt-3" style={{width:"100%"}} src="https://www.youtube-nocookie.com/embed/6wD_SpEknso" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
        </div>
        <div className="column is-3">
          <iframe className="mt-3" style={{width:"100%"}} src="https://www.youtube-nocookie.com/embed/gEdw-Gf9_-I" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
        </div>
        <div className="column is-3">
          <iframe className="mt-3" style={{width:"100%"}} src="https://www.youtube-nocookie.com/embed/DaL9mH7twzU" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
        </div>
      </div>
      <h2 className="mt-6" id="anniversaires">Les anniversaires</h2>
      <div className="columns is-vcentered mt-3">
        <div className="column is-3">
          <iframe className="mt-3" style={{width:"100%"}} src="https://www.youtube-nocookie.com/embed/PHJfDQGOeY8" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
          <iframe className="mt-3" style={{width:"100%"}} src="https://www.youtube-nocookie.com/embed/ix7Fo92JDM4" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
          <iframe className="mt-3" style={{width:"100%"}} src="https://www.youtube-nocookie.com/embed/QOQKmOEllXY" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
        </div>
        <div className="column ml-6 mr-6">
          <p>
            Ils ponctuent nos vies en moments agréables en réunissant notre
            famille, nos amis.
          </p>
          <p>
            Je m’adapte à vos goûts musicaux et exigences mais également selon
            la configuration des lieux pour créer une ambiance qui vous est
            propre.
          </p>
          <p>
            Ambiance intime et calme pendant le repas, jusqu’aux moments de fête
            sur la piste de danse
          </p>
          <p>
            Je suis mobile sur l’ensemble du département du Jura et dans le
            départements limitrophes
          </p>
          <p>
            Je vous propose un catalogue de plus de 10 000 titres parmi lesquels
            vous trouverez certainement des chansons de vos artistes préférés.
          </p>
          <p>
            Je dispose d’un écran, et d’un vidéoprojecteur de haute qualité, et
            je saurai m’adapter à votre espace ou salle. (Tarif sur demande).
          </p>
        </div>
        <div className="column is-2 has-text-centered">
          <Img
            fixed={data.ballons.childImageSharp.fixed}
            alt="ballons"
            style={{ maxWidth: 123, width: "100%" }}
          />
        </div>
      </div>
      <div className="columns">
        <div className="column">
          
        </div>
        <div className="column">
        
        </div>
        <div className="column">
        
        </div>
      </div>
      <h2 className="mt-4" id="mariages">Les mariages</h2>
      <div className="columns is-vcentered">
        <div className="column is-2 has-text-centered">
          <Img
            fixed={data.mariage.childImageSharp.fixed}
            alt="mariage"
            style={{ maxWidth: 117, width: "100%" }}
          />
        </div>
        <div className="column">
          <p>
            Moment crucial et important de vos vies, je vous accompagne par
            plusieurs rendez vous afin de mieux vous connaître et connaître vos
            envies.
          </p>
          <p>
            Plusieurs ambiances sont possibles selon le rythme de votre journée
            : apéritif, vin d’honneur, repas, soirée…
          </p>
          <p>
            “Wherever you go, wherever you do, I will be right here, I waiting
            for you…” Richard Marx. Dj "ludo" se déplace sur l'ensemble du Jura
            et des départements limiitrophes.
          </p>
        </div>
      </div>
      <h2 className="mt-6" id="thes">Thés dansants</h2>
      <div className="columns is-vcentered">
        <div className="column">
          <p>"Un incontournable de vos après-midi associatif"</p>
          <p>
            Les danses de salon seront bien sûr au rendez vous, mais également
            les autres de 60 à nos jours!{" "}
          </p>
          <p>
            Dansons joue contre joue ! Venez comme vous êtes, chics ou
            décontractés, l'essentiel est de passer un bon moment ensemble.
          </p>
        </div>
        <div className="column is-3 has-text-centered">
          <Img
            fixed={data.thes.childImageSharp.fixed}
            alt="thes"
            style={{ maxWidth: 200, width: "100%" }}
          />
        </div>
      </div>
      <h2 className="mt-6" id="loto">Loto</h2>
      <div className="has-text-centered mb-2 mt-2">
        <Img
          fixed={data.loto.childImageSharp.fixed}
          alt="loto"
          style={{ maxWidth: 504, width: "100%" }}
        />
      </div>
      <p>
        Un animateur doit savoir s’adapter à toute les ambiances, d’une ambiance
        endiablée d’une soirée dansante, à une ambiance plus feutrée où la
        diction de la valse des numéros doit être parfaite et audible. Je mets
        mon expérience et mon matériel professionnel à votre service. Pour un
        moment récréatif et convivial, écran, videoprojecteur, sono vous sont
        proposés.
      </p>
      <h2 className="mt-6" id="sport">Manifestations sportives</h2>
      <iframe className="mt-5" width="560" height="315" src="https://www.youtube-nocookie.com/embed/KfQiCinezlA" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
      <h2 className="mt-6" id="event">Sonorisation de vos événements</h2>
      <div className="columns is-vcentered mt-2">
        <div className="column is-2 has-text-centered">
          <Img
            fixed={data.micro.childImageSharp.fixed}
            alt="micro"
            style={{ maxWidth: 226, width: "100%" }}
          />
        </div>
        <div className="column">
          <p>
            Grâce à une sono de marque HK, made in Germany, vous bénéficier
            d’une qualité de son irréprochable. Pas besoin de pousser le volume
            fort, il suffit d’un son clair et chaleureux pour les voix lors de
            discours, mais également pour des remises de prix, récompenses, pots
            de départ…
          </p>
          <p>
            Je tiens également à votre disposition, plusieurs microphones avec
            ou sans fil, et d’un pied.
          </p>
        </div>
        <div className="column is-2 has-text-centered">
          <Img
            fixed={data.enceinte.childImageSharp.fixed}
            alt="enceinte"
            style={{ maxWidth: 68, width: "100%" }}
          />
        </div>
      </div>
      <div className="mt-6" style={{ fontFamily: "made", fontSize: 30 }}>
        <Link to="/fr/agenda/">☞ Mes prestations à venir</Link>
      </div>
    </Layout>
  )
}

export default AnimFr
